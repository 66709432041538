import * as React from 'react';
import { Component } from 'react';
import ArticleLayout from "../../articles/article-layout";
import kayla_img_1 from '../../../images/KaylaQuimbley.jpg';
import kayla_img_1_mobile from '../../../images/KaylaQuimbley_mobile.jpg';

const fullName = "Kayla Quimbley";

const copyList = [

    {
        type: "image",
        content: {
            src: kayla_img_1,
            mobileSrc: kayla_img_1_mobile,
            alt: "Kayla Quimbley",
            caption: "Kayla Quimbley"
        }
    },
    {
        type: "text",
        content: "<h3 class=\"title\">A conversation with Kayla Quimbley, a National Youth HIV and AIDS Awareness Ambassador, member of the Presidential Advisory Council on HIV/AIDS, and nationally acclaimed poet"
    },
    // {
    //     type: "subheading2",
    //     content: "<p class=\"sub-title2\">The Rising Star"
    // },

    {
        type: 'text',
        content: "“I was born into this world under such unique circumstances,” says Kayla Quimbley, a 24-year-old powerhouse HIV activist, educator, and youngest member of the Presidential Advisory Council on HIV/AIDS (PACHA). Once a cheerful 3rd grader growing up in Albany, Georgia, HIV hit her world in a painfully personal way. As she recalls, “My mom was having a conversation with her friend, whose child overheard and then went back to school and told people I had HIV. I didn’t know what it was, but I knew it was something bad because the kids were laughing and pointing.” Soon, she began to wonder why she always took medicine. That’s when her mother shared news she was too young to fully process at the time: that she’d acquired HIV at birth. “I don’t think I asked any questions. It was like, ‘Oh, okay.’”"
    },
    {
        type: "blockquote",
        content: "“I was born into this world under such unique circumstances.”",
        author: fullName
    },
    {
        type: 'text',
        content: "But as she progressed in school, things were anything but okay. The hurt from bullying and ridicule was compounded by misinformation coming from untrained and ill-equipped teachers. By middle school, fear kicked in as existential questions swirled in her brain: “‘Am I ever going to be able to have sex? Am I going to be able to have kids? Is anybody going to want to be in a relationship with me?’ I was depressed and scared.” It wasn’t until she found a safe haven at a Georgia summer camp for youth living with HIV that she began to thrive. “It was like a week for me to feel seen, heard, and validated.”"
    },
    {
        type: "text",
        content: "In that annual refuge, Quimbley found her confidence and, at the age of 15, decided to use her voice. When a team from the organization AIDS Atlanta visited the camp seeking out submissions for a national poetry contest, Quimbley, who’d found solace in writing since the age of 10, knew it was her time to shine. “I called Momma and said, ‘I’m about to do a big thing and I need your permission because standing in my truth means simultaneously you have to do the same.’” After a moment of silence, “She said, ‘Baby, go ahead.’” Ask Quimbley; it was God’s timing."
    },
    {
        type: "text",
        content: `She won the contest with a deeply personal poem about her journey from feeling stigmatized for her HIV status 
        to standing proud, and it set her on the road to becoming the fierce advocate she is today. Through 
        traveling across the country as an ambassador for National Youth HIV and AIDS Awareness Day, along 
        with Advocates for Youth,* “I became a catalyst. Every concept that they had of HIV I completely 
        shattered. It felt good that I could shift the narrative,” she says of her work breaking down stigma among 
        youth and fostering the same types of loving environments that helped her find her voice. Now studying 
        for her master’s in public health, she looks back on her journey thus far with 
        pride. “It molded me. While I didn’t envision all 
        of this, I’ve always known I’d have an impactful life.”`
    },
    { type: 'disclaimer'},
    // {
    //     type: "signatureName",
    //     content: "<h2 class=\"signatureName\">KAYLA QUIMBLEY"
    // },
    // {
    //     type: "signaturedesc",
    //     content: "<h2 class=\"signaturedesc\">Advocates for Youth, PACHA"
    // },
    {
        type: "championsofchangelandinglink",
          content: "<a class=\"championsofchangelandinglink\" href=\"/championsofchange\">Learn more about all our Champions of Change honorees "

    },

]


class Content extends Component {
  render() {
    return (
      <div className={`championsofchangearticle ${this.props.show ? 'd-block' : 'd-none'} championsofchange`}>

      <ArticleLayout
        page={this.props.page}

        copyList={copyList}
        subheading="Champions of Change: A Celebration of Black Women Changemakers in HIV"
        heading="Kayla Quimbley, The Rising Star"
      />

        {/*<Container fluid className={`${this.props.page} hero`} />
        <Container className={`${this.props.page}`}>
          <Row>
            <Col xs={9} lg={10} className='m-auto px-0'>
              <div className='hero-callout muted-yellow-dark'>
                <h1 className='sub-title '>Dafina</h1>
                <h1 className='title '>Dafina Ward - Champion of Change</h1>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </Col>
          </Row>
    </Container>*/}
      


      </div>
    );
  }
}

export default Content;
